.FormMP {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;

  * { margin-top: 10px; }

  input {
    max-width: 300px;
    font-size: 1rem;
  }

  .nav-button {
    width: auto;
    padding: 0 5px;
  }
}
