.secret_developer_page, .secret-developer-page {
  height: 100vh;

  .dev-options {
    padding: 2vh 0;
    width: 100%;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    .prefill-button, .timeout-button { 
      height: 60px;
      width: 300px;
      background-color: grey;
      margin-bottom: 5px;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;

      &:focus, &:active { background-color: lightgreen}
     }

     
  }

  #secret-nav-buttons-container {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    height: 75vh;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    

    .nav-button {
      width: 280px;
      margin: 2px;
    }
  }
}

@media only screen and (max-width: 834px){
  .secret_developer_page, .secret-developer-page {
    #secret-nav-buttons-container { height: 150vh } 
  }
}

@media only screen and (max-width: 719px){
  .secret_developer_page, .secret-developer-page {
    .dev-options {
      display: flex;
      flex-direction: column;
      align-items: center;

      .prefill-button{
        margin: 0 auto 5px auto;
      }
    }
    #secret-nav-buttons-container { height: auto } 
  }
}