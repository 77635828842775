@import '../../../scss/colors';
@import '../../../scss/mixins';

.info-box {
  width: 200px;
  position: absolute;
  color: white;
  z-index: 20;
  
  &.up .info-box-up-arrow { visibility: visible}
  &.down .info-box-down-arrow { visibility: visible}
  
  $info-box-arrow-border-style: 10px solid;
  %info-box-body-style {
    $info-box-body-padding: 10px;
    background-color: $info-box-background-color;
    padding-left: $info-box-body-padding;
    padding-right: $info-box-body-padding;
  }
  %shared-arrow-spec {
    $white-border-size: $info-box-arrow-border-style transparent;
    border-left: $white-border-size;
    border-right: $white-border-size;
    width: 0;
    position: relative;
    visibility: hidden;
    box-sizing: border-box;
  }
  
  .span-paragraph p { 
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .info-box-up-arrow {
    @extend %shared-arrow-spec;
    border-bottom:20px solid $info-box-background-color;
  }
  
  .info-box-down-arrow {
    @extend %shared-arrow-spec;
    border-top: 20px solid $info-box-background-color;
  }
  
  .info-box-head {
    @extend %info-box-body-style;
    font-size: 14px;
    font-style: italic;
    line-height: 18px;
    padding: 7px 18px 3px 10px;
    
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    position: relative;
  }

  .info-box-body {
    @extend %info-box-body-style;
    border-top: 1px dotted white;
    line-height: 1.2;
    padding: 6px 10px 10px 10px;
    font-family: "Lucida Sans","Lucida Grande",'Lucida Sans Unicode','Lucida',Verdana,Arial,sans-serif;
  }

  button {
    right: 0;
    position: absolute;
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    padding: 0;
    height: 24px;
    width: 24px;
    border: none;
    color: transparent;
    font-size: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }

}
span.info-box button { top:3px; }

div.info-box button { top: 23px; }

@media only screen and (max-width: 579px) {
  .info-box .info-box-body .span-paragraph p { font-size: 11px; }
}
