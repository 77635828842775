@import '../../../scss/dimensions';

$icon-size: 6.6vw;

.egg-array-container {
  box-sizing: border-box;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 660px;
  padding: 25px 80px 40px;

  $icon-size: 40px;

  .egg-icon {
    height: $icon-size;
    width: $icon-size;
    box-sizing: border-box;
    margin: 5px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    &.safe { background-image: url('./safe_egg.png'); }
    &.fertilized { background-image: url('./fertilized_egg.png'); }
  }
}

@media only screen and (min-width: 1000px){
  .egg-array-container {
    height: 285px;
    width: 100%;
    padding: 20px 0 40px 0;
    -webkit-flex-direction: column;
flex-direction: column;

    .egg-icon { margin: 2px;}
  }
}

@media only screen and (max-width: 719px){
  .egg-array-container {
    
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 380px;

    .egg-icon {
      height: $icon-size;
      width: $icon-size;
      max-height: 30px;
      max-width: 30px;
      margin: 4px;
    }
  }
}

@media only screen and (max-width:414px){
  .egg-array-container {
    max-width: 90vw; 

    .egg-icon { 
      margin: 1vw;  
    }
  }
}
