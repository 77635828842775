@import '../../../scss/mixins';

.side_effect_preface {
  position: relative;

  .markdown-block p { 
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .burst-image {
    position: absolute;
    bottom: 55px;
    right: -72px;
    width: 530px;
    z-index: -1;
  }
}


@media only screen and (min-width: 980px) {
  .side_effect_preface {
    .heading-container, .markdown-block {
      width: 640px;
      align-self: flex-start;
    }
    
    .burst-image {
      width: 54vw;
      bottom: 0;
      margin-right: 70px;
      float: right;
      display: block;
      shape-outside: url('./side-effects-burst.png');
      shape-margin: 50px;
    }
    
  }
}

@media only screen and (max-width: 680px) {
  .side_effect_preface {
    .burst-image {
      right: -90px;
      bottom: 0;
      width: 365px;
    }

    .markdown-block p{ @include mobile-paragraph-style }
  }
}