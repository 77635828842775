@import '../../../scss/colors';
@import '../../../scss/mixins';

.health_before_pregnancy_topics {
 
  .topic-container {
    margin-bottom: 20px;
  }
  .topic-container:last-child {
    margin-bottom: 30px;
  }

  .markdown-block {
    p:last-of-type {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 719px) {
 .health_before_pregnancy_topics {
    h1 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    .markdown-block p {
      font-size: 16px;
      line-height: 24px;

      &:last-of-type { margin-bottom: 30px; }
    }
  }
}
