.survey5_mbc {
  .survey-questions-container { 
    width: 100%; 

    .survey-question-container {
      width: 100%; 

      .question-text { margin-bottom: 10px; }

      .survey-choices-container .survey-choice { 
        min-width: 0;
        width: 200px;
        margin: 10px 15px;
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .survey5_mbc {
    .survey-questions-container {
      margin-bottom: 20px;

      .survey-question-container .survey-choices-container .survey-choice {
        margin: 0 0 20px 0;
        width: 100%;
        -webkit-flex-grow: 1;
        flex-grow: 1;
      }
    }
  }
}