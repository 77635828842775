@import '../../../scss/mixins';

.overlay {
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 200;
  -webkit-flex-direction: column;
  flex-direction: column;

  .modal {
    background-color: white;
    width: 500px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    margin: auto 0;

    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    button.close-x {
      //make a transparent X
      background-image: url('./close-icon.png');
      background-color: transparent;
      background-size: contain;
      border: none;
      color: transparent;
      //positioning & sizing
      font-size: 0;
      position: absolute;
      right: -7px;
      top: -7px;
      padding: 15px;
    }

    .modal-content-container {
      box-sizing: border-box;
      flex: 1 0 100%;
      overflow: auto;
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
    }

    .modal-content {
      margin: 20px 0;
    }

    .scroll-down {
      position: sticky;
      bottom: 48vh;
      right: 20px;
      align-self: flex-end;
      visibility: hidden;
      
      width: 16px;
    }
  }
}

@media only screen and (max-width: 719px) {
  .overlay .modal {
    .modal-content {
      p, input::placeholder {
        @include mobile-paragraph-style;
      }
    }

    .scroll-down.visible {
      visibility: visible;
    }
  }
}

@media only screen and (min-width: 720px) {
  .overlay .modal {
    margin: 20px 0;
    max-height: calc(100% - 40px);

    .modal-content-container {
      padding: 0 20px;
    }

    .scroll-down {
      transform: translateX(-16px);
    }
  }
}
