@import '../../../scss/colors';
@import '../../../scss/mixins';

.overlay#email-modal {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;

  .modal {
    padding: 0 20px;
    // background-color: pink;

    .modal-content {
      width: 100%;
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      align-items: stretch;

      p { 
        // font-size: 20px;
        margin-bottom: 20px;
      }

      input[type="text"] {
        border: 1px solid $dark_gray;
        height: 40px;
        padding: 8px;
        box-sizing: border-box;
        font-size: 20px;
        margin-bottom: 10px;
        
        &.error {  border: 1px solid $dark_orange; }
        &.success {  border: 1px solid $dark_green; }
        &::placeholder {
          font-style: italic;
        }
      }
      .message {
        height: 20px;
        margin-bottom: 10px;

        p.error { color: $dark_orange}
        p.success { color: $dark_green }
      }

      .flex-group {
        display: flex;
      }
        
      .nav-buttons-container {
        padding-bottom: 0px;

        .nav-button.disabled { background-color: $medium_gray}
      }
    }
  }
}
