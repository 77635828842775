@import '../scss/colors';
@import '../scss/mixins';

// debugging styles

// .root {
//   color: red;

//    .background-image {
//       p, ul li {color:red}

//       .heading-container h1 {
//         color: red;
//         background-color: lightblue;
//       }
//     }

//     .splash.background-image .flexbox-group .nav-buttons-container .nav-button {
//       color: red;
//       background-color: lightblue;
//     }

//     .overlay .modal { background-color: lightblue;}
//     .big-deal .intro.background-image .nav-buttons-container .nav-button {
//       color: red;
//       background-color: lightblue;
//     }

//     .main.background-image .nav-buttons-container .nav-button { color: red}

//     h1 {color: red}
//    .background-image {

//       .bc-options-container {
//         .bc-bars-and-brackets-container .bc-bar-item {
//           border-color: red;
//         }
//         .hint .hint-body { color: red }
//       }
//       .effectiveness-brackets-container .brackets span {
//         background-color: lightblue;
//         color: red;
//       }

//       .icon-column-container .icon-and-map-container .info-box {
//         .info-box-head, .info-box-body, {
//           background-color: purple;
//           color: orange;
//         }
//         .info-box-up-arrow{
//           border-bottom-color: purple;
//         }
//         .info-box-down-arrow {
//           border-top-color: purple;
//         }
//       }

//       .selection-bar-container .selection-bar button {
//         background-color: lightblue;
//         color: red;
//         &.selected {
//           background-color: lightblue;
//         }
//       }
//     }

//     .survey_page.background-image .survey-questions-container .survey-question-container .survey-choice { color: red }

// }

// CSS reset

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  padding: 0;
}

// *:focus { outline: 0 !important}
// *:focus {outline: purple solid 4px}

// style for elements that are given tab focus, but don't need to show it visually
.focus-start { outline: 0 }

// hide outline when using mouse .using-mouse class is added/removed with mouse/keyboard input
body.using-mouse :focus {
  outline: none;
}

// common utility styles
html {
  position: fixed;
  overflow-y: hidden;
}

body {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
button { cursor: pointer;}
.hidden {visibility: hidden }
.no-display {
  position: absolute;
  top: 0;
  display: none;
}

.screen-reader-only {
  position: absolute;
  font-size: 1px;
  color: transparent;
}

strong, b { font-weight: bold }
em { font-style: italic }

// common styles
#root, html {
  height: 100%;
}

body {
  -webkit-tap-highlight-color: transparent;
  p, a, span, div, button, textarea { font-family:georgia; }
}

#root {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  position: relative;

  // display: -webkit-flex;
  // display: flex;
  // -webkit-flex-direction: column;
  // flex-direction: column;

  // overflow-y: scroll;
  
  // This is to ensure scroll momentum works correctly on an actual iphone
  -webkit-overflow-scrolling: touch;

  transition: none !important;

}

.background-image {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  box-sizing: border-box;
  padding: 0 5vw;
  min-height: calc(100% - 15px);

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  // -webkit-flex: 1 0 auto;
  // flex: 1 0 auto;
}

.NavigationManager {
  width: 100%;
  margin-top: auto;

  &.no-margin-top { margin-top: 0; }
}

@media only screen and (max-width: 719px) {
  .NavigationManager {
    height: 70px;

    .nav-buttons-container {
      position: fixed;
      bottom: -1px;
      width: 90vw;
      background: rgba(255, 255, 255, 0.8);
      padding-top: 10px;
      z-index: 150;
    }
  }
}

@media only screen and (min-width: 1000px){
  .background-image {
    padding-left: calc((100vw - 900px)/2);
    padding-right: calc((100vw - 900px)/2);
  }
}

.left-align {
  align-self: flex-start;
}

@media print {
  html, body, #root {
    position: initial;
    overflow-x: visible;
    overflow-y: visible;
  }

  .rainbow { display: none }
}
