@import '../../../scss/mixins';

.survey3_mbc{
  .markdown-block p { margin-bottom: 0; }

  .survey-questions-container{ 
    width: 100%; 
    margin-top: 15px;

    .survey-question-container.multi {
      width: 100%; 

      .survey-categories-container {
        width: 100%; 

        .survey-category-container {
          margin-bottom: 15px;
          width: 100%; 

          .category-name { margin-right: 7px; }

          .survey-choice { 
            min-width: 0;
            width: 100px;
            height: 50px;
            box-sizing: border-box;
            padding: 0 10px;
            margin: 0 7.5px;
            line-height: 1.25;
            
            &:last-of-type { margin-right: 0;}
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .survey3_mbc { @include mobile-multi-part-question; }
}

@media only screen and (min-width: 835px){
  .survey3_mbc .survey-questions-container .survey-question-container.multi {
    .survey-categories-container .survey-category-container .survey-choice { 
      width: 15vw;
      max-width: 150px;
    }
  }
}