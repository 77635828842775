.path4you-return {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  span {
    color: white;
    font-family: georgia, serif;
    font-style: italic;
    font-size: 22px;
    font-weight: 500;
    margin-right: 2px;
  }

  img {
    height: 36px;
    vertical-align: middle;
  }

  .button {
    background-color: #852065;
    display: block;
    padding: 4px 8px 0;
  }
}

@media only screen and (max-width: 719px){
  .path4you-return {
    span {
      font-size: 15px;
      line-height: 28px;
    }

    img { height: 25px; }

    .button { padding: 0 6px 0 8px; }
  }
}
