@import '../../../scss/mixins';

$rainbow-height: 15px;
$heading-pad-top: 20px;
$heading-pad-btm: 4px;
$heading-line-height: 38px;
$image-height: 742px;
$uncropped-image-width: 484px;
$image-offset: 140px;
$heading-total-height: 1 * $heading-line-height + $heading-pad-btm + $heading-pad-top;
$min-page-height: $rainbow-height + $heading-total-height + $image-height;
$mobile-image-height: 440px;
$mobile-min-page-height: 582px;
$mobile-heading-total-height: 49px;

.fertility_future.background-image {
  background-image: url('./return-to-fertility-silhouette@2x.png');
  background-position: right $image-offset * -1 bottom;
  background-size: $uncropped-image-width $image-height;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  padding: 0;
  height: calc(100% - 15px);
  min-height: 855px;

  .heading-container { padding-bottom: 3px }

  .markdown-block { 
    padding-top: 13px;

    p { 
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 24px;
    }
  }

  .heading-container, .markdown-block { 
    padding-left: 5vw; 
    padding-right: 5vw; 
  }

  .NavigationManager .nav-buttons-container { 
    background-color: transparent;
    position: absolute;
    bottom: 0;
    max-width: 885px;
  }
  .image-space {
    float: right;
    width: $uncropped-image-width - $image-offset;
    min-height: $image-height - ($min-page-height - 800px);
    shape-outside: inset(800px - $image-height 0 0 0);
  }

  // &.safari.not-standalone .image-space {
  //   margin-top: calc(100vh - 830px);
  // }
}

@media only screen and (min-width: 835px){
  .fertility_future.background-image  {
    background-position: right bottom;

    .image-space {
      width: $uncropped-image-width;
    }
  }
}

@media only screen and (min-width: 1000px){
  .fertility_future.background-image  {
    padding-left: calc((100vw - 885px) / 2);
    padding-right: calc((100vw - 885px) / 2);
    min-height: 785px;

    .image-space { 
      width: calc(#{$uncropped-image-width} - (100vw - 885px) / 2);
      shape-outside: inset(0 0 0 0);
    }

    .heading-container, .markdown-block { 
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (min-width: 1200px){
  .fertility_future.background-image {
    min-height: 768px - 15px;

    .image-space { min-height: 650px}
  }
}

@media only screen and (max-width: 719px){
  .fertility_future.background-image  {
    background-size: $uncropped-image-width * 0.65 $image-height * 0.65;
    min-height: 605px;

    .markdown-block p { 
      font-size: 16px;
      margin-bottom: 16px;
      line-height: 1.3;
    }

    .image-space {
      shape-outside: inset(93px 0 0 0);
      height: $mobile-image-height;
      width: 0.65 * $mobile-image-height - 115px;
      margin-top: 93px;
      shape-margin: 0;
      min-height: 0;
    }

    &.safari.not-standalone .image-space {
      margin-top: 93px;
    }

    .NavigationManager .nav-buttons-container { 
      width: 100%;
      padding: 10px 5vw 30px;
    }
  }
}

@media only screen and (max-width: 500px){
  .fertility_future.background-image {
    min-height: 635px;
  }
}

@media only screen and (max-width: 374px){
  .fertility_future.background-image {
    min-height: 740px;

    .image-space { height: 475px;}
  }
}

// @media only screen and (max-width: 719px) and (min-height: 598px){
//   .fertility_future.background-image {
//     .image-space {  margin-top: calc(100vh - 598px + 93px);  }

//     &.safari.not-standalone .image-space {
//       margin-top: calc(100vh - 598px + 93px); 
//     }
//   }
// }

// @media only screen and (max-width: 374px) and (min-height: 700px){
//   .fertility_future.background-image .image-space {
//     margin-top: calc(100vh - 700px + 173px);

//     &.safari.not-standalone .image-space {
//       margin-top: calc(100vh - 700px + 173px);
//     }
//   }
// }

// @media only screen and (max-height: 800px) and (min-width: 720px){
//   .fertility_future.background-image {
//     // min-height: $mobile-min-page-height;

//     .image-space { margin-top: 0 }

//     &.safari.not-standalone .image-space {
//       margin-top: 0;
//     }
//   }
// }
