@import '../../../scss/mixins';

#instructions-container {
  text-indent: 0px;
  width: 220px;
  position: absolute;
  background: #669900;
  bottom: 73px;
  left: 50%;
  margin-left: -110px;
  color: #FFFFFF;
  box-sizing: border-box;
  text-align: left;
  border-radius: 3px;
  z-index: 100;

  .instructions-head {
    font-size: 16px;
    line-height: 16px;
    font-style: italic;
    font-weight: normal;
    border-bottom: 1px dotted white;
    padding: 10px 10px 5px 10px;
  }

  .instructions-body {
    padding: 5px 10px 10px 10px;
    font-size: 13px;
    line-height: 19px;
    font-family: "Lucida Sans","Lucida Grande","Lucida Sans Unicode","Lucida",Verdana,Arial,sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .instructions-arrow {
    border-top: 12px solid #669900;
    border-bottom: 0px solid #669900;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    font-size: 0px;
    line-height: 0%;
    position: absolute;
    bottom: -12px;
    left: 102px;
    width: 0px;
  }
  
  .instructions-close {
    cursor: pointer;
    background: no-repeat url('../../../shared_images/x-icon.png');
    position: absolute;
    height: 15px;
    width: 16px;
    top: 10px;
    right: 10px;
    border: none;
  }

  &.topic-modal {
    bottom: auto;
    top: -90px;

    .instructions-head { display: none; }
    .instructions-body { padding: 10px; }
  }

  &.summary-reminder {
    bottom: -105px;
    background-color: #005566;

    .instructions-arrow {
      bottom: auto;
      top: -12px;
      border-bottom: 12px solid #005566;
      border-top: 0px solid #005566;
    }
  }
}


@media only screen and (min-width: 719px) {
  #instructions-container {
    bottom: 83px;
  }
}
