@import '../../../scss/colors';
@import '../../../scss/mixins';

.main_nav_mp, .main_nav {
  background-position: right;
  color: white;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;

  .heading-container h1{   color:white; }
  

  .main-nav-buttons-container {
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 0 30px;
    position: initial;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 0;
    width: 610px;
    
    .nav-button {
      box-sizing: border-box;
      width: 100%;
      
      margin-bottom: 20px;
      text-align: left;
      background-size: cover;
      box-shadow: 2px 2px 8px -1px black;
      &.visited { 
        background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
      }

      &:nth-child(1) { background-color: $orange;}
      &:nth-child(2) { background-color: $dark_orange;}
      &:nth-child(3) { background-color: $lighter_green;}
      &:nth-child(4) { background-color: $dark_green;}
      &:nth-child(5) { background-color: $light_blue;}
      &:nth-child(6) { background-color: $dark_gray_blue;}
      &:nth-child(7) { background-color: $dark_gray_blue;}

      a {
        display: -webkit-flex; 
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        line-height: 1.75;
        padding: 17px 25px 17px 20px;

        .main-nav-arrow{
          width: 4%;
          height: 4%;
          position: relative;
        }

        .main-nav-check {
          width: 5.5%;
          height: 5.5%;
          position: relative;
          left: 1%;
        }

        p { 
          font-family: "Archer SSm A", "Archer SSm B";
          font-size: 1.5em;
          font-style: italic;
          font-weight: 700;
          text-transform: none;
        }
      }
    }
  }

  &.es .main-nav-buttons-container .nav-button a p { font-size: 20px;}
}

@media only screen and (min-width: 835px){
  .main_nav_mp, .main_nav {
    .main-nav-buttons-container .nav-button a { 
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}

// @media only screen and (max-width: 670px) {
@media only screen and (max-width: 719px) {
  .main_nav_mp, .main_nav {
    background-position: right top;
    background-size: cover;

    .aria .heading-container { @include mobile-heading-style }

    .main-nav-buttons-container{
      width: 100%;

      .nav-button {
        margin-bottom: 10px;
        &.visited {  background-size: 200% auto; }
        
        a {
          padding: 14px 3%;
          
          p {  
            line-height: 1.35;  
            font-size: 14px;
          }
        }
      }
    }
    &.es .main-nav-buttons-container .nav-button a p { font-size: 14px;}
  }
}

@media only screen and (min-width: 501px) and (max-width: 719px) {
  .main_nav_mp, .main_nav {
    .main-nav-buttons-container .nav-button a p { font-size: 18px; }

    &.es .main-nav-buttons-container .nav-button a p { font-size: 18px;}
  }
}

@media only screen and (max-width: 374px){
  .main_nav_mp, .main_nav {
    .main-nav-buttons-container .nav-button a p { white-space: pre }
   }
}
