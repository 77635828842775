.back-to-in-control {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  height: 36px;

  span {
    color: white;
    font-family: georgia, serif;
    font-style: italic;
    font-size: 22px;
    line-height: 36px;
    margin: 0 6px;
    display: inline-block;
    vertical-align: top;
  }

  img {
    height: 100%;
  }

  .button {
    background-color: #393653;
  }
}

@media only screen and (max-width: 719px){
  .back-to-in-control {
    height: 28px;

    span {
      font-size: 15px;
      line-height: 28px;
    }
  }
}
