@import '../../../scss/colors';
@import '../../../scss/mixins';

.background-image.how_to_women {
  .icon-column-container {
    background-repeat: no-repeat;
    background-image: url('../../../shared_images/silhouettes/female-silhouette-large.png');

    .icon-and-map-container {
      margin:10px 0;

      .icon-button {
        z-index: 5;
        position: relative;
      }

      .map { z-index: 1; }
    }

    .pill {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/pill-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/pill-green-ON.png');}
      }

      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/pill-map.png');
        top: calc(50% - 10px);
      }

    }

    .implant {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/implant-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/implant-green-ON.png');}
      }

      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/implant-map.png');
        top: calc(50% - 5px);
        width: 151px;
      }
    }

    .patch {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/patch-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/patch-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/patch-map.png');
        bottom: calc(50% + 2px);
      }
    }

    .iud {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/iud-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/iud-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/iud-map.png');
        bottom: calc(50% - 1px);
        height: 143px;
      }
    }

    .diaphragm {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-map.png');
        bottom: calc(50% - 1px);
        height: 216px;
      }
    }
    
    .shot {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/shot-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/shot-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/shot-map.png');
        background-position: right;
        top: calc(50% - 3px);
        height: 42px;

      }
    }
    
    .sterilization {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/sterilization-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/sterilization-map.png');
        background-position: right;
        top: calc(50% - 4px);
        width: 230px;
      }
    }

    .ring {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/ring-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/ring-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/ring-map.png');
        background-position: right;
        bottom: 50%;
        height: 68px;
        width: 210px;
      }
    }

    .femaleCondom {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/female-condom-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/female-condom-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/female-condom-map.png');
        background-position: right;
        bottom: 50%;
        height: 170px;
        width: 210px;
      }
    }

    .icon-and-map-container .info-box {
      &.pill { top: 48px; }
      &.implant {
        top: 60px;

        .info-box-up-arrow { left: 48px;}
      }

      &.patch {
        bottom: 76px;

        .info-box-down-arrow { left: 60px;}
      }

      &.iud { bottom: 189px;}

      &.diaphragm { bottom: 262px;}

      &.shot {
        top: 83px;

        .info-box-up-arrow { left: 160px;}
      }

      &.sterilization { top: 73px;}

      &.ring { bottom: 116px; }

      &.femaleCondom {  bottom: 222px;}
    }
  }
}

@media only screen and (max-width: 579px){
  .background-image.how_to_women {
    .icon-column-container .icon-and-map-container {
      margin: 6px 0;

      &.pill {
        .map {
          width: 122px;
          top: calc(50% - 4px);
        }
        .info-box {
          top: 30px;

          .info-box-up-arrow { left: 75px }
        }
      }

      &.implant {
        .map { width: 92px; }

        .info-box {
          top: 34px;

          .info-box-up-arrow { left: 45px }
        }
      }

      &.patch {
        .map {
          width: 100px;
          height: 19px;
        }

        .info-box {
          bottom: 48px;

          .info-box-down-arrow {
            left: 52px
          }
        }
      }

      &.iud {
        .map {  height: 90px; }

        .info-box {
          bottom: 114px;

          .info-box-down-arrow {
            left: 74px
          }
        }
      }

      &.diaphragm {
        .map {  height: 133px;  }

        .info-box {
          bottom: 158px;

          .info-box-down-arrow {
            left: 72px
          }
        }
      }

      &.shot {
        .map {
          width: 82px;
          height:28px;
        }

        .info-box {
          top: 48px;

          .info-box-up-arrow {
            left: 115px
          }
        }
      }

      &.sterilization {
        .map {  width: 142px; }

        .info-box {
          top: 45px;

          .info-box-up-arrow {
            left: 75px
          }
        }
      }

      &.ring {
        .map { height: 43px; }

        .info-box {
          bottom: 68px;

          .info-box-down-arrow {
            left: 73px
          }
        }
      }

      &.femaleCondom {
        .map {
          height: 107px;
          width: 130px;
        }

        .info-box {
          bottom: 132px;

          .info-box-down-arrow {
            left: 74px
          }
        }
      }
    }
  }
}
