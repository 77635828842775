@import '../../scss/colors';

#inactivity-modal {
  z-index: 5000;
  
  .text {
    font-size: 20px;
    color: $dark_gray;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 719px) {
  #inactivity-modal div { width: 90% }
}