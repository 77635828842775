@import '../../../scss/colors';
@import '../../../scss/dimensions';

.effectiveness {
  position: relative;

  .bc-plain-texts-container { 
    position: relative;

    .screen-reader-only.effective-text {
      position: absolute;
      bottom: 10%;
      left: 50%;
     }
   }

  .mobile { display: none }

  .bc-bars-and-brackets-container, .bc-bars-and-brackets-mobile-container {
    .no-display { float: right }

    $spacing-between-brackets: 2px solid white;
    .brackets {
      height: 100%;
      background-size: contain;
      border-left: $spacing-between-brackets;
      border-right: $spacing-between-brackets;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      z-index: 1;
      font-size: 13px;
      padding-bottom: 5px;

      span {
        padding: 0 4px;
        color: $dark_gray;
        background-color: white;
        font-style: italic;
        position: relative;
      }

      &.bracket-0 {
        background-image: url('./less-effective-brackets.png');
        width: 3 * $method-box-width;
        border-left: none;
      }
      &.bracket-1 {
        background-image: url('./effective-brackets.png');
        width: 4* $method-box-width;

      }
      &.bracket-2 {
        background-image: url('./most-effective-brackets.png');
        width: 3 * $method-box-width;
        border-right: none;

      }
    }

    .bc-bar-items-container {

      .bc-bar-item {
        position: relative;

        &.noMethod {
          border-left: none;
          background-size: cover;
          background-image: url('../../../shared_images/illustrated_method_icons/no-method-OFF-1.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/no-method-on.png'); }
        }
        &.femaleCondom {
          background-image: url('../../../shared_images/illustrated_method_icons/female-condom-orange-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/female-condom-on-color.png'); }
        }
        &.maleCondom { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-orange-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-on-color.png'); }
        }
        &.diaphragm { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-orange-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-on-color.png'); }
        }
        &.pill { background-image: url('../../../shared_images/illustrated_method_icons/pill-blue-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/pill-on-color.png'); }
        }
        &.patch { background-image: url('../../../shared_images/illustrated_method_icons/patch-blue-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/patch-on-color.png'); }
        }
        &.ring { background-image: url('../../../shared_images/illustrated_method_icons/ring-blue-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/ring-on-color.png'); }
        }
        &.shot { background-image: url('../../../shared_images/illustrated_method_icons/shot-blue-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/shot-on-color.png'); }
        }
        &.implant { background-image: url('../../../shared_images/illustrated_method_icons/implant-green-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/implant-on-color.png'); }
        }
        &.iud { background-image: url('../../../shared_images/illustrated_method_icons/iud-green-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/iud-on-color.png'); }
        }
        &.sterilization {
          background-image: url('../../../shared_images/illustrated_method_icons/sterilization-green-OFF.png');

          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-on-color.png'); }
        }

        div.screen-reader-only { 
          position: absolute;
          width: 100%; 
          height:100%;
         }
      }
    }

    .bc-result-container { width: $method-box-width * 11;}
  }

  .bc-bars-and-brackets-container {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    .brackets-container {
      padding-left: $method-box-width;
      padding-bottom: 3px;
      box-sizing: border-box;
    }
  }

  .bc-bars-and-brackets-mobile-container {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    .bracket-and-bar-group {
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      margin-bottom: 13px;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;

      .brackets {
        position: relative;
        right: 0.6vw;
        margin-top: 6px;
        padding-bottom: 7px;
        min-height: 19px;
        height: 3.5vw;
        &.bracket-0 { width: $mobile-large-icon * 3 - 1.2vw }
        &.bracket-1 {width: $mobile-large-icon * 4 - 1.2vw }
        &.bracket-2 {width: $mobile-large-icon * 3 - 1.2vw }
      }

      .bc-bar-items-container .bc-bar-item {
        height: $mobile-large-icon;
        width: $mobile-large-icon;
      }
    }
  }
}

@media only screen and (min-width: 1000px){
  .effectiveness {
    .bc-plain-texts-container .heading-container { padding-top: 15px; }

    .bc-bars-and-brackets-container {
      .bc-bar-items-container {
        .bc-bar-item {
          height: $method-box-width-large;
          width: $method-box-width-large;
        }
      }

      .bc-result-container {
        width: 11 * $method-box-width-large;
      }

      .brackets-container {
        padding-left: $method-box-width-large;

        .brackets {
          &.bracket-0 {width: 3 * $method-box-width-large; }
          &.bracket-1 {width: 4 * $method-box-width-large; }
          &.bracket-2 {width: 3 * $method-box-width-large; }
        }
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .effectiveness {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    position: static;

    .regular { display: none }
    .mobile { display: block }

    .bc-bars-and-brackets-mobile-container {
      display: -webkit-flex; 
      display: flex;
      padding-bottom: 17px;

      .brackets { font-size: calc(0.6em + 0.8vw); }
    }
  }
}

@media only screen and (max-width: 719px){
  .effectiveness {
    .mobile-graphics-container .overlay .modal .egg-array-container {
      padding: 0;

      .egg-icon { background-size: 100%;}
    }
  }
}
