@import '../../../scss/colors';
@import '../../../scss/mixins';

$icon-graphic-width: 100px;
$icon-extra-width-for-text: 5px;
$icon-total-width: $icon-graphic-width + 2 * $icon-extra-width-for-text;
$small-icon-graphic-width: 80px;
$small-icon-total-width: $small-icon-graphic-width + 2 * $icon-extra-width-for-text;

.current_methods.background-image {
  padding: 0; 

  .heading-container, .markdown-block, .nav-buttons-container { 
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .markdown-block {
    width: 100%;
    box-sizing: border-box;

    p { margin-bottom: 17px; }
  }

  .icon-container {
    @include flexbox-icon-container;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 30px 0;
    max-width: 750px;
    align-self: center;

    .icon-button {
      @include flexbox-icon;
      height: 135px;
      width: $icon-total-width;
      margin: 14px 15px;
      background-color: white;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;

      label {
        font-size: 13px;
        white-space: pre-wrap;
        text-align: center;
        max-width: 115px;
        
      }

      .icon {
        height: $icon-graphic-width;
        width: $icon-graphic-width;
        box-sizing: border-box;
        border: 2px solid;
        border-color: #ffffff;
        margin-bottom: 5px;
        position: relative;
        @include method-icon-actual-image-style();
        
        &.active {
          border-color: $link_green;

          &::after {
            height: 100%;
            width: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            background-size: 45px auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('./check-mark.svg')
          }
        }

      }
    }
  }
}

// .current_methods lsited twice to make the rules more specific
.current_methods.current_methods.background-image {
  .icon-container .icon-button {
    height: 145px;
  }
}

@media only screen and (min-width: 1000px){
  .current_methods.current_methods.background-image {
    .heading-container, .markdown-block, .nav-buttons-container { 
      padding-left: calc((100vw - 900px) / 2);
      padding-right: calc((100vw - 900px) / 2);
    }

    .markdown-block p { margin-bottom: 9px; }

    .icon-container .icon-button { margin: 5px 15px; }
  }
}

@media only screen and (max-width: 579px){
  .current_methods.current_methods.background-image {
    .markdown-block p {
      @include mobile-paragraph-style;
      margin-bottom: 20px;
    }

    .icon-container {
      width: 90vw;
      justify-content: space-around;
      .icon-button {
        width: $icon-total-width;
        margin: 7px 1px;

        .icon {
          width: $icon-graphic-width;
          height: $icon-graphic-width;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 374px){
  .current_methods.current_methods.background-image {
    .icon-container .icon-button {
      width: $small-icon-total-width;
      height: auto;
      min-height: $small-icon-graphic-width + 25px;

      .icon {
        width: $small-icon-graphic-width;
        height: $small-icon-graphic-width;
      }
    }
  }
}
