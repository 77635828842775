@import '../../../scss/mixins';

.emergency_contraception {
  .markdown-block {
    p:last-of-type {margin-bottom: 20px}
  }

  .flex-group {
    
    display: -webkit-flex; 
    display: flex;
    flex-direction: column-reverse;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    height: auto;

    .ellipse-space {
      width: 90vw;
      height: 0.9 * 103vw;
      max-height: 490px;
      shape-outside: inset(300px 0 0 0);
      position: relative;
      float: right;
      background-image: url('./iud-ec@2x.jpg');
      background-size: 501px 516px;
      background-position: center top;
      background-repeat: no-repeat;

    }
  }

  &.es .flex-group .ellipse-space {  
    height: 0.5 * 103vw;
  }
}

@media only screen and (min-width: 835px){
  .emergency_contraception{
    .flex-group {
      display: block;

      .ellipse-space {
        width: 501px;
        height: 596px;
        max-height: 596px;
        shape-outside: inset(80px 0 0px 0px);
        background-position: right top 80px;
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .emergency_contraception {
    .flex-group .ellipse-space {
      background-size: contain;
      float: none;
      max-height: 348px;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
    }

    .markdown-block p {
      @include mobile-paragraph-style;
      margin-bottom: 24px;

      &:last-of-type {margin-bottom: 9px}
    }
  }
}
