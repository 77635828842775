@import '../../../scss/colors';
@import '../../../scss/mixins';

#view-notes-button {
  position: absolute;
  width: 27px;
  height: 30px;
  bottom: 30px;
  left: 50%;
  margin-left: -13.5px;
  background: #fff url('./notes-icon.svg') no-repeat;
  background-size: contain;
  text-indent: -5000px;
  border: none;

  &:disabled {
    visibility: hidden;
  }
}

#notes-modal.overlay {
  @include note-sized-modal-width;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;

  .modal {
    height: 60vh;
    margin: 40px auto 0;
    box-sizing: border-box;
    
    border: none;
    border-radius: 6px;
    color: $dark-gray;

    h1 {
      font-family: "Archer SSm A", "Archer SSm B";
      font-weight: 700;
      color: $dark_blue;
      margin: 15px 0 0;
      font-size: 20px;
      line-height: 30px;
    }

    .scroll-container {
      p.long, p.short {
        font-size: 16px;
        line-height: 24px;
        font-style: italic;
        margin-bottom: 15px;
      }
  
      p.short { display: block; }
      p.long { display: none; }
  
      .textarea-container {
        -webkit-flex: 1;
        flex: 1;
        position: relative;
        max-height: 160px;
        height: 20vh;
        @include note-textarea-and-placeholder;
  
      }
    }
  }

  button {
    top: auto;
    color: #ffffff;
    background: $dark_blue;
    font-size: 15px;
    line-height: 20px;
    width: 100px;
    height: 30px;
    font-family: Georgia;
    font-style: italic;
    position: absolute;
    bottom: 20px;
    border-radius: 0;
    border: none;

    &.save { right: 20px; }
    &.cancel { left: 20px; }
  }
}



@media only screen and (min-width: 720px) {
  #notes-modal.overlay .modal .scroll-container {
    p.short { display: none; }
    p.long { display: block; }
  }

  #view-notes-button {
    width: 36px;
    height: 40px;
    margin-left: -18px;
  }
}

@media only screen and (min-width: 1000px) {
  #notes-modal.overlay .modal {
    margin: auto;

    .scroll-container .textarea-container {
      max-height: unset;
    }
  }
}

// device-specific styling
@media only screen and (max-width: 320px) {
  #notes-modal.overlay .modal {
    h1 { font-size: 18px; }
    .textarea-container { height: 100px; }
    .scroll-container p.short { margin-bottom: 10px; }
  }
}
@media only screen and (max-height: 350px) {
  .android #notes-modal.overlay .modal {
    min-height: 300px;
    bottom: 80px;
  }
}
