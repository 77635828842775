@import '../../../scss/colors';
@import '../../../scss/mixins';

.is-mypath.splash {
  background-image: url('../../../shared_images/splash-mp-new-background.jpg');
  background-position: center;
  flex:1 0 auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  %spacer {
    content: '';
    height: 1px;
    width: 100px;
  }
  
  .spacer-1 {
    @extend %spacer;
    -webkit-flex:1;
    flex: 1;
  }
  .spacer-2 {
    @extend %spacer;
    -webkit-flex: 0.35;
    flex: 0.35;
  }

  .flexbox-group {
    display: flex;
    display: -webkit-flex; 
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    height: 380px;
    position:relative;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;

    .my-reproductive-health-journey {
      width: 633px;
      height: 380px;
      z-index: 1;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('./mypath.png');
    }

    .locale-buttons-container {
      padding-right: 17px;
      box-sizing: border-box;
      bottom: 8px;

      .nav-button {
        width: 198px;
        border: 1px solid white;
      }
    }
  }
}

.mypathPeripartum, .mypathPeripartumVA, .impact {
  &.splash .flexbox-group .my-reproductive-health-journey {
    background-image: url('./mypathPeripartum.png');
  }
}

@media only screen and (min-width: 835px){
  .is-mypath.splash {
    .spacer { flex: 0.25; }
  }
}

@media only screen and (max-width: 719px){
  .is-mypath.splash {
    .spacer-2 { flex: 1; }

    .flexbox-group {
      width: 100%;

      .my-reproductive-health-journey {
        width: 100%;
        height: 190px;
        left: 6px;
        max-width: 380px;
      }

      .locale-buttons-container{
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        bottom: 12px;
        padding: 0;

        .nav-button { width: 247px; }
      }
    }
  }
}