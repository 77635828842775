@import '../../scss/colors';
@import '../../scss/mixins';

$circle-size: 22px;
$circle-padding: 3px;

.barrier_survey {
  h2 {
    width: 350px;
    margin: 0 20px 10px 0;
    font-size: 18px;
    font-weight: bold;
    color: $medium_blue;

    @media only screen and (max-width: 719px){
      margin: 0 0 10px;
      width: 280px;
    }
  }

  .survey-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1000px){
      width: 100%;
    }

    .slider-and-question {
      margin-bottom: 65px;
      display: flex;
      width: 100%;
      justify-content: space-between;
  
      @media only screen and (max-width: 719px){
        flex-direction: column;
        margin-bottom: 80px;
        
      }
  
      .slider-and-labels {
        width: 280px;
  
        @media only screen and (min-width: 1000px){
          width: 350px;
        }

        .slider {
          height: 20px;
      
          .track {
            height: 2px;
            background: $dark_gray;
            position: relative;
            top: calc(($circle-size - 2px) / 2);
          }
      
          .mark {
            box-sizing: border-box;
            background: white;
            height: $circle-size;
            width: $circle-size;
            border-radius: calc($circle-size / 2);
            border: 1px solid $dark_gray;
          }
      
          .thumb {
            height: $circle-size;
            width: $circle-size;
            padding: $circle-padding;
            box-sizing: border-box;
      
            .thumb-solid {
              background: $medium_blue;
              height: $circle-size - $circle-padding - $circle-padding;
              width: $circle-size - $circle-padding - $circle-padding;
              border-radius: calc(($circle-size - $circle-padding - $circle-padding) / 2);
              cursor: pointer;
            }
          }
        }

        .flex-group {
          display: flex;
          justify-content: space-between;
      
          .slider-label-container {
            position: relative;
            width: $circle-size;
            height: 1px;
      
            .slider-label {
              font-style: italic;
              font-size: 13px;
              color: $dark_gray;
              padding-top: 5px;
              position: absolute;
              width: 50px;
              text-align: center;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
  }

  .textarea-container{ 
    position: relative;
    min-height: 150px;
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    @include note-textarea-and-placeholder;
  }
}
