@mixin apply-color($color, $background: lightgray) {
  background-color: $background;
  border-color: $color;

  h1 { color: $color; }
  svg path { fill: $color; }
}

.study-id-image, .study-id-mini {
  h1 {
    font-family: Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }

  div { background-color: lightgray; }

  .red    { @include apply-color(red); }
  .green  { @include apply-color(green); }
  .blue   { @include apply-color(blue); }
  .orange { @include apply-color(orange); }
  .yellow { @include apply-color(yellow, darkgray); }
  .purple { @include apply-color(purple); }
  .brown  { @include apply-color(#8b4513); }
  .pink   { @include apply-color(pink, darkgray); }
  .silver { @include apply-color(silver, #666); }
}

.study-id-image {
  div {
    border: solid 1vw;
    border-radius: 10px;
    max-height: 360px;
    margin: 2vh auto;
    padding: 4vw;
    max-width: 360px;
  }

  h1 {
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.1rem;
  }

  svg { margin: 2vw; }
}

.study-id-mini {
  div {
    border: solid 2px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 8px 0;
    padding: 0 8px;
  }

  h1 {
    font-size: 1rem;
    margin-right: 4px;
    max-width: 160px;
    width: 20vw;
  }

  svg {
    display: block;
    margin: 5px;
    height: 40px;
    width: 40px;
  }
}