@import '../../../scss/colors';
@import '../../../scss/mixins';

.health_before_pregnancy {
  .markdown-block {
    width: 100%;

    p em { text-decoration: underline }
  }

  .visually-hidden {
    display: none;
  }

  .items-container {
    
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0px auto 10px auto;
    max-width: 740px;

    .item {
      margin: 0 auto 20px;

      a { 
        text-decoration: underline;
        color: blue;
      }

      button.item-button {
        width: 125px;
        background: #fff;
        font-size: 13px;
        line-height: 1.5;
        position: relative;
      }

      .image {
        display: block;
        width: 125px;
        height: 125px;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid rgb(191, 191, 191);
        box-sizing: border-box;
        margin-bottom: 5px;
      }

      .visited-overlay-image {
        display: block;
        position: absolute;
        top: 0;
        width: 125px;
        height: 125px;
        background: url('./check@2x.png');
        background-size: contain;
      }

      &.healthy_lifestyle .image {
        background-image: url('./healthy-lifestyle@2x.png');
      }
      &.healthy_weight .image {
        background-image: url('./healthy-weight@2x.png');
      }
      &.medical_conditions .image {
        background-image: url('./medical-conditions@2x.png');
      }
      &.mental_health .image {
        background-image: url('./mental-health@2x.png');
      }
      &.medications .image {
        background-image: url('./medications@2x.png');
      }
      &.relationships .image {
        background-image: url('./relationships@2x.png');
      }
      &.your_body .image {
        background-image: url('./YourBody.svg');
      }
      &.substances .image {
        background-image: url('./AlcTobDrugs.svg');
      }
      &.breastfeeding .image {
        background-image: url('./Breastfeeding.svg');
      }
      &.complicated_pregnancy .image {
        background-image: url('./HealthAfter.svg');
      }
      &.infections .image {
        background-image: url('./infections@2x.png');
      }
      &.vaccines .image {
        background-image: url('./vaccines@2x.svg');
      }
      &.spacing_between_babies .image {
        background-image: url('./birth-spacing@2x.png');
      }
    }
  }

  .overlay {
    .modal {
      width: 92vw;
      max-width: 885px;
      color: #404040;
  
      h1 {
        @include archer;
        font-size: 28px;
        margin: 6px 0 20px;
        color: $link_green;
  
        span {
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: 1px;
          width: 24px;
          height: 24px;
          display: block;
          border: 1px solid #bfbfbf;
          margin-right: 8px;
          float: left;
        }
      }
  
      .topic-container {
        margin-bottom: 20px;
      }
  
      p {
        margin-bottom: 20px;
        font-family: Georgia;
        font-size: 20px;
        line-height: 1.3;
      }

      ul { 
        list-style-type: disc;
        font-size: 20px;
        line-height: 1.3;
        padding-left: 21px;

        li { margin-bottom: 20px; }
      }
    }
  
    &#healthy_lifestyle .modal h1 span {
      background-image: url('./healthy-lifestyle@2x.png');
    }
    &#medical_conditions .modal h1 span {
      background-image: url('./medical-conditions@2x.png');
    }
    &#mental_health .modal h1 span {
      background-image: url('./mental-health@2x.png');
    }
    &#medications .modal h1 span {
      background-image: url('./medications@2x.png');
    }
    &#relationships .modal h1 span {
      background-image: url('./relationships@2x.png');
    }
    &#your_body .modal h1 span {
      background-image: url('./YourBody.png');
    }
    &#substances .modal h1 span {
      background-image: url('./AlcTobDrugs.png');
    }
    &#breastfeeding .modal h1 span {
      background-image: url('./Breastfeeding.png');
    }
    &#complicated_pregnancy .modal h1 span {
      background-image: url('./HealthAfter.png');
    }
    &#infections .modal h1 span {
      background-image: url('./infections@2x.png');
    }
    &#vaccines .modal h1 span {
      background-image: url('./vaccines@2x.png');
    }
    &#spacing_between_babies .modal h1 span {
      background-image: url('./birth-spacing@2x.png');
    }
  }
}

/* desktop */
@media screen and (min-width: 1000px) {
  .health_before_pregnancy {
    .items-container {
      max-width: 900px;

      .item {
        margin-right: 40px;
      }
    }
  }
}


/* mobile */
@media screen and (max-width: 719px) {
  .health_before_pregnancy {
    .heading-container { padding-bottom: 10px; }
    
    .markdown-block p {
      font-size: 16px;
      line-height: 24px;
    }
    
    .items-container {
      .item {
        &:nth-child(2n) {
          margin: 0px 0px 20px 0px;
        }
        &:nth-child(2n+1) {
          margin: 0px 20px 20px 0px;
          margin-right: calc(90vw - 250px - 30px);
        }

        button.item-button {
          width: 125px;
          background: #fff;
          font-size: 13px;
          line-height: 1.5;
          position: relative;
        }
      }
    }


    .overlay .modal {
      @include mobile-full-screen-menu-item-modal;

      .modal-content {
        h1 {
          font-size: 20px;
          line-height: 24px;
          padding-left: 0;
          color: $dark_blue;

          span { display: none }
          // background: none !important;
        }
      }

      p, ul li {
        font-size: 16px;
        line-height: 24px;
      }

      button.close-x {
        display: none;
      }
    }
  }
}
