@import '../../../scss/colors';
@import '../../../scss/mixins';

.heading-container {
  padding: 20px 0 16px 0;
  align-self: flex-start;
  width: 100%;
  box-sizing: border-box;

  h1 {
    color: white;
    line-height: 1.3;
    padding: 4px 0;
    @include archer;
    font-size: 24px;
    color: $dark_blue;
  }
}

.markdown-block p, .markdown-block ul, .markdown-block h2 {
  font-size: 20px;
  line-height: 1.3;
}

.markdown-block p, .markdown-block ul { margin-bottom: 20px;}

.markdown-block ul {
  list-style-type: disc;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
}

 @media only screen and (max-width: 719px){
   .background-image >.heading-container { 
    padding: 15px 0 12px ;
 
     h1 {
       font-size: 18px;
       padding: 4px 0;
       white-space: pre-wrap;
     }
   }

  .markdown-block p, .markdown-block ul, .markdown-block h2{ font-size: 16px;}
  .markdown-block p, .markdown-block ul { margin-bottom: 16px;}
 }