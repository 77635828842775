@import '../../../scss/mixins';

.background-image.intro_pregnant, .background-image.intro_postpartum {
  @include intro_page_mp;
}

@media only screen and (max-width: 719px){
  .background-image.intro_pregnant, .background-image.intro_postpartum {
    .nav-buttons-container::after {
      background-color: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 200%;
      content: "";
      position: absolute;
      top: 0;
      left: -50%;
      z-index: 5;
    }

    .nav-button { z-index: 15;}
  }
}