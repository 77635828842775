@import '../../../scss/colors';
@import '../../../scss/mixins';

.sex_after_baby {
  .topic-container { margin-bottom: 10px; }

  .menu-container {
    @include square-flexbox-menu-container;
  
    .menu-item {
      @include flexbox-menu-item;
      width: 200px;
      height: 200px;
    }
    
    .overlay{
      &.hidden { display: none }
      
      .modal .modal-content {
        &.first h1 { background-image: url('./talking-with-your-partner.svg')}
        &.third h1 { background-image: url('./listening-to-your-body.svg')}
        &.fourth h1 { background-image: url('./real-talk.svg')}

        .markdown-block ul li p { margin-bottom: 10px }
      }
    }
  }
}

@media only screen and (max-width: 719px) {
  .sex_after_baby {
    .markdown-block p, .markdown-block ul { font-size: 16px; }

    .menu-container { 
      flex-direction:  column;

      .menu-item { margin-bottom: 20px;}
    }
  }
}

@media only screen and (min-width: 1000px) {
  .sex_after_baby .menu-container { justify-content: space-evenly;  }
}
