@import '../../../scss/colors';
@import '../../../scss/mixins';

.survey_page {
  .markdown-block p { margin-bottom: 0;}
  >.markdown-block {
    width: 100%;

    >p { margin-bottom: 20px; }
  }

  .survey-questions-container {
    margin-bottom: 20px;

    .survey-question-container {

      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;

      &.hidden {
        visibility: visible;
        display: none;
      }
      &.vertical .survey-choices-container {
        -webkit-flex-direction: column;
        flex-direction: column;
        margin-bottom: 0;

        .survey-choice {
          width: 315px;
          margin-bottom: 20px;
          white-space: pre-wrap;
          &:first-of-type { margin-left: 10px }
          &:last-of-type { margin-right: 10px }
        }
      }
      &.multi .survey-categories-container {
        .survey-category-container {

          display: -webkit-flex;
          display: flex;
          margin-bottom: 20px;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;

          .category-name {
            -webkit-flex: 1;
            flex: 1;
            text-align: right;
            font-size: 19px;
            font-weight: bold;
            color: $medium_blue;
            margin-right: 10px;
          }

          .survey-choice { 
            min-width: 130px;
            cursor: pointer;
          }
        }
      }

      h2 { font-weight: bold }

      .survey-choices-container {
        margin-bottom: 20px;

        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center;
      }

      .question-text {
        width: 100%;
        font-size: 1.25em;
        line-height: 1.3;
        margin-bottom: 20px;
      }

      .survey-choice {
        -webkit-flex-grow: 0;
        flex-grow: 0;
        font-style: italic;
        font-size: 13px;
        min-width: 150px;
        height: 40px;
        margin: 0 10px;
        border: 1px solid;
        border-radius: 3px;
        background-color: $white_gray;
        border-color: $dark_gray;
        color: $dark_gray;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-of-type { margin-left: 0 }
        &:last-of-type { margin-right: 0 }
        &.active {
          background-color: $light_blue;
          border-color: $dark_blue;
          color: $dark_blue;
          font-weight: bold;
        }

        input {
          opacity: 0;
          width: 0;
          height: 0;
          position: absolute;
        }
      }
    }
  }
}

@media only screen and (min-width: 835px){
  .survey_page .survey-questions-container {
    width: 100%;
    margin-bottom: 15px;

    .survey-question-container.vertical .survey-choices-container .survey-choice {
      margin-bottom: 15px;
    }
  }
}
@media only screen and (max-width: 745px){
// @media only screen and (max-width: 719px){
  .survey_page {
    .markdown-block p {
      @include mobile-paragraph-style;
      margin: 0 0 10px 0;
    }

    .survey-questions-container {
      margin-bottom: 10px;

      .survey-question-container {
        -webkit-flex-direction: row;
        flex-direction: row;
        &.hidden {
          visibility: visible;
          display: none;
        }
        &.vertical .survey-choices-container .survey-choice {
          width: 100%;

          &:first-of-type { margin-left: 0 }
          &:last-of-type { margin-right: 0 }
        }

        .question-text {
          font-size: 16px;
          line-height: 1.625;
          margin: 0 0 20px 0;
        }

        .survey-choices-container {
          width: 100%;
          padding: 0 10px;
          margin-bottom: 0;

          .survey-choice {
            width: 100%;
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .survey_page {
    @include mobile-multi-part-question;

    .survey-questions-container .survey-question-container.multi .survey-categories-container .survey-category-container{
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-evenly;
      justify-content: space-evenly;
      margin-bottom: 14px;

      .category-name {
        width: 90vw;
        -webkit-flex: 1 0 auto;
        flex: 1 0 auto;
        margin: 0 0 19px 0;
        text-align: center;
        font-size: 18px;
      }

    }
  }
}

@media only screen and (max-width: 340px){
  .survey_page .survey-questions-container .survey-question-container .survey-choice {
    white-space: pre;
  }
}
