/********  Colors  ********/

$dark_gray:         #404040;
$dark_medium_gray:  #606060;
$medium_gray:       #808080;
$medium_gray_508:   #757575;
$light_gray:        #bfbfbf;
$x_light_gray:      #efefef;
$xx_light_gray:     #f6f6f6;
$white_gray:        #f2f2f2;
$dark_white_gray:   #d9d9d9;
$light_blue:        #83cfdd;
$medium_blue:       #009cba;
$dark_blue:         #005566;
$dark_gray_blue:    #004d5d;
$lightest_green:    #afca40;
$lighter_green:     #acce19;
$light_green:       #a2c800;
$dark_green:        #5d9200;
$olive:             #94b800;
$link_green:        #669900;
$yellow:            #fade1d;
$orange:            #faac1d;
$dark_orange:       #f37928;
$purple:            #8d2e8d;
$dark_purple:       #521a53;
$pink:              #e9007b;
$dark_pink:         #c9005b;
