@import '../../../scss/colors';
@import '../../../scss/mixins';

.fertility {
  .markdown-block {
    width: 100%;
  }

  .menu-container {
    $menu-item-width: 290px; 

    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;

    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    margin-top: 10px;

    &.single {
      -webkit-flex-direction: column;
      flex-direction: column;
    }

    .menu-item {
      @include flexbox-menu-item;
      width: $menu-item-width;
      height: 245px;
      margin: 0 0 20px 0px;
      -webkit-flex-grow: 0;
      flex-grow: 0;
    }
    .menu-item.second { margin-right: 0;}
    .menu-item.fourth { margin-right: 0;}

    .overlay .modal {
      p { 
        font-size: 20px;
        line-height: 1.5;
      }

      .menu-item-modal-copy {
        margin: 0 0 20px;
    
        p {
          font-family: Georgia;
          font-size: 20px;
          line-height: 1.5;
          font-style: normal;
          font-weight: normal;
        }
    
        p:first-child { margin-top: 0; }
      }
    }
  }

  #menstrual-cycle-image {
    display: block;
    width: 500px;
    box-sizing: border-box;
    margin: 10px auto 20px;
  }
}

@media only screen and (max-width: 599px){
  .fertility .menu-container {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (max-width: 719px) {
 .fertility {
    #menstrual-cycle-image {
      width: 100%;
    }

    .menu-container {
      .menu-item {
        margin: 0 0 20px;
        width: auto;
        max-width: 310px;
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .mobile { display: none; }
}

@media only screen and (min-width: 835px) {
  .fertility {
    .aria .markdown-block p {
      font-size: 20px;
      line-height: 26px;
    }

    #menstrual-cycle-image {
      width: 350px;
    }

    #fertility-by-age {
      .button-bar {
        margin: 10px auto;
      }

      .pie-graph {
        width: 350px;
        height: 350px;
        margin: 10px auto;
      }

      .caption {
        top: 25%;

        .chance {
          font-size: 36px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .fertility .menu-container {
    flex-direction: row;
  }
}

.es.fertility .menu-container .menu-item {
  height: 300px;
}
