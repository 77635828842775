@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/dimensions';

.how_to_page {
  position: relative;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;

  &.how_to_men .icon-column-container {background-size: 385px;}
  &.how_to_women .icon-column-container {background-size: 343px;}

  >.markdown-block p {
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .icon-column-container {
    box-sizing: border-box;
    
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: space-between;
    align-content: space-between;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 40px 0;
    height: 515px;
    width: 542px;
    -webkit-flex: 0 0 515px;
    flex: 0 0 auto;
    background-position: center;
    background-size: 343px;
    min-height:0;

    .icon-and-map-container {
      position: relative;
      -webkit-flex: 0 0 $how-to-icon-size;
      flex: 0 0 $how-to-icon-size;

      .icon-button {
        height: $how-to-icon-size;
        width: $how-to-icon-size;
        display: block;
        background-size: cover;
        background-color: white;
        z-index: 10;
        position: relative;
        cursor: pointer;
      }

      .map {
        width:  200px;
        height: 32px;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 5;
        &.left { left: $how-to-icon-size; }
        &.right { right: $how-to-icon-size; }
      }

      .info-box {
        &.left{ left: 169px}
        &.right { right: 169px}

        .info-box-up-arrow, .info-box-down-arrow { left: 90px;}
      }
    }
  }
}

@media only screen and (min-width: 835px){
  .how_to_page {
    .icon-column-container {
      margin-top: -4px;
    }
  }
}

@media only screen and (max-width: 719px){
  .how_to_page .heading-container { 
    @include mobile-heading-style;
  }
}

@media only screen and (max-width: 579px){
  .how_to_page {
    &.how_to_men .icon-column-container {background-size: 212px;}
    &.how_to_women .icon-column-container {background-size: 212px;}

    .icon-column-container {
      max-width: 296px;
      height: 318px;
      background-size: 212px;
      margin: 14px 0 40px 0;
      -webkit-flex: 0 0 318px;
      flex: 0 0 318px;

      $how-to-icon-size-mobile: 50px;

      .icon-and-map-container {
        -webkit-flex: 0 0 $how-to-icon-size-mobile;
        flex: 0 0 $how-to-icon-size-mobile;

        .icon-button {
          height: $how-to-icon-size-mobile;
          width: $how-to-icon-size-mobile;
        }

        .map {
          width: 123px;

          &.left { left: $how-to-icon-size-mobile - 20px}
          &.right { right: $how-to-icon-size-mobile - 20px}
        }

        .info-box {
          width: 170px;

          &.left { left: 63px; }
          &.right { right: 63px; }

          .info-box-up-arrow, .info-box-down-arrow { left: 75px;}
        }

      }
    }
  }
}
