@import '../../../scss/colors';

.ribbon { 
  position: fixed;
  right: calc(-5vw + 25px);
  top: 40px;
  width: 244px;
  height: 54px;
  background-image: url('../../../shared_images/ribbon.png');
  background-size: contain;
  background-repeat: no-repeat;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  color: white;
  font-style: italic;
  font-size: 16px;
  line-height: 2.4;
  z-index: 50;

  a { 
    width: 100%;
    padding-left: 20px;
  }
}

.nav-buttons-container .nav-button.fake-ribbon {
  background-color: $link_green
}

@media only screen and (min-width: 835px){
  .ribbon { 
    right: 0;
    top: 35px;
  }
}

@media only screen and (max-width: 719px){
  .ribbon { top: 1.5vw;}
}