.current_methods_survey {
  .survey-questions-container .survey-question-container{
    .survey-choice { 
      width: 315px;
      margin-bottom: 20px;
    }
  }
  
  .closing-text .markdown-block {
    padding-bottom: 4px;
    
    p {
      font-style: italic;
      margin-bottom: 26px;
    }
  }
}
 
@media only screen and (min-width: 835px){
  .current_methods_survy .survey-questions-container {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 755px){
  .current_methods_survey {
    .survey-questions-container { margin-bottom: 0 }
    
    .closing-text .markdown-block p { 
      line-height: 1.625em; 
    }
  }
}