@import '../../../scss/mixins';

.survey4_mbc{
  .survey-questions-container{ 
    width: 100%; 

    .survey-question-container.multi {
      width: 100%; 

      .survey-categories-container {
        width: 100%; 
        padding: 0 50px 0 0;

        .survey-category-container {
          width: 100%; 
          -webkit-justify-content: center;
          justify-content: center;

          .category-name { 
            margin-right: 10px; 
            max-width: 200px;
          }

          .survey-choice { 
            min-width: 0;
            width: 100px;
            height: 40px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 719px){
  .survey4_mbc {
    @include mobile-multi-part-question;
    
    .survey-questions-container .survey-question-container.multi .survey-categories-container {
      .survey-category-container {
        -webkit-justify-content: space-evenly;
justify-content: space-evenly;

        .category-name { max-width: none; }
      }
    }
  }
}