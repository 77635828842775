@use "sass:math";

@import '../../../scss/colors';

.rainbow {
  height: 15px;
  -webkit-flex: none;
  flex: none;

  >div {
    width: math.div(1, 6) * 100% ;
    height: 100%;
    display: inline-block;

    &.yellow-box { background-color: $orange}
    &.red-box { background-color: $dark_orange}
    &.lime-box { background-color: $light_green}
    &.green-box { background-color: $dark_green}
    &.blue-box { background-color: $light_blue}
    &.indigo-box { background-color: $medium_blue}
  }
}