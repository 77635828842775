@import '../../../scss/colors';
@import '../../../scss/mixins';

.intro_mp {
  @include intro_page_mp;

  &.mypathPeripartumVA .nav-buttons-container {
    flex-direction: column;

    .nav-button {
      width: 100%;
      margin-bottom: 10px;
      color: black;
      background: white;
      text-align: left;
      padding: 14px 3%;
    }
  }
}

#disclaimer.overlay { 
  h1 {
    @include archer;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .modal-content {
    margin-bottom: 0;

    p { margin: 0; }
  }
}

@media only screen and (max-width: 719px){
  .intro_mp:not(.mypathPeripartumVA) .nav-buttons-container {
    &::after {
      background-color: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 200%;
      content: "";
      position: absolute;
      top: 0;
      left: -50%;
      z-index: 5;
    }

    .nav-button { z-index: 15;}
  }

  #disclaimer.overlay .modal {
    @include intro-mobile-modal-style;
  }
}
