@import '../../../scss/mixins';
@import '../../../scss/colors';

$icon-graphic-width: 125px;
$icon-extra-width-for-text: 8px;
$icon-total-width: $icon-graphic-width + 2 * $icon-extra-width-for-text;
$max-icon-margin: 21px;
$small-icon-graphic-width: 110px;
$small-icon-total-width: $small-icon-graphic-width + 2px;
$extra-small-icon-graphic-width: 90px;
$extra-small-icon-total-width: $extra-small-icon-graphic-width + 2px;

.recommendations {
  position: relative;

  .mobile { display: none }

  .markdown-block p {
    margin-bottom: 20px;

    span {
      width: 16px;
      height: 16px;
      background-image: url('./question-mark-icon.svg');
      background-size: contain;
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }

  &.es .recs-toggle { width: 560px; }

  .recs-toggle {
    @include archer;
    text-transform: none;
    width: 500px;
    height: 70px;
    align-self: center;
    font-size: 19px;
    padding: 0 5px;
    color: white;
    background-color: $dark_blue;
    background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
    background-size: cover;
  }

  .recs-container {
    margin-top: 9px;

    .rec-section-container {

      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;

      .markdown-block { width: 100% }

      .rec-section-icons-container {

        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        max-width: ($icon-total-width) * 4 + ($max-icon-margin * 6);
        -webkit-justify-content: center;
        justify-content: center;

        #none-text {
          margin-bottom: 20px;
          font-size: 20px;
        }

        .icon-container {
          position: relative;
          margin-left: calc((90vw - 4px - #{($icon-total-width) * 4}) / 6);
          margin-right: calc((90vw - 4px - #{($icon-total-width) * 4}) / 6);
          margin-bottom: 30px;
          &:last-of-type { margin-right: 0; }
          &:nth-of-type(1) { margin-left: 0}

          .rec-icon-container {
            width: $icon-total-width;
            background-color: transparent;
            // background-color: yellow;

            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: center;

            &.active .icon {
              border: 1px solid $dark_blue;

              &:after {
                height: $icon-graphic-width - 2px;
                width: $icon-graphic-width - 2px;
                content: "";
                position: absolute;
                box-sizing: border-box;
                top: 1px;
                left: $icon-extra-width-for-text + 1px;
                z-index: 10;
                border-radius: 8px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: repeating-linear-gradient(
                  135deg,
                  rgba(0, 100, 119, 0.25),
                  rgba(0, 100, 119, 0.25) 1px,
                  transparent 1px,
                  transparent 4px
                );
              }
            }

            .icon {
              height: $icon-graphic-width;
              width: $icon-graphic-width;
              box-sizing: border-box;
              border-radius: 8px;
              background-position-x: 50%;
              @include method-icon-actual-image-style();
            }

            >label {
              font-size: 16px;
              margin-top: 6px;
              // background-color: lightblue;
              text-align: center;

              >span.question-mark {
                position: relative;
                bottom: 6px;
                padding: 8px;
                width: 16px;
                height: 16px;
                display: inline-block;
                // background-color: pink;
                background-image: url('./question-mark-icon.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                box-sizing: border-box;
                margin-left: 2px;
                font-size: 0;

                .span-arrow.info-box-up-arrow, .span-arrow.info-box-down-arrow {
                  @include shared-arrow-spec;
                  position: absolute;
                  visibility: visible;
                  z-index: 50;
                  padding: 0;
                  left: 50%;
                  height: 25px;
                  transform: translateX(-50%);
                  // background-color: red;
                }
                .span-arrow.info-box-up-arrow {
                  top: 13px;
                  border-bottom:20px solid $info-box-background-color;
                }
                .span-arrow.info-box-down-arrow {
                  border-top:20px solid $info-box-background-color;
                  bottom: 13px;
                  top: auto;
                }
              }
            }
          }

          .info-box {
            z-index: 20;
            width: 32.5vw;
            max-width: 300px;
            text-align: left;
            left: -60px;
            &.info-box-up-arrow { top: 38px }
            &.info-box-down-arrow { bottom: 38px }

            .info-box-head { display: flex; }

            .info-box-body {
              white-space: pre-wrap;
              line-height: 18px;
              display: inline-block;

              .markdown-block p {
                font-size: 13px;
                margin-top: 0;
                margin-bottom: 8px;
                display: inline-block;
                &:last-of-type { margin-bottom: 0}
              }
            }
          }
        }
      }
    }
  }

  .nav-buttons-container {
    margin-top: 9px;

    .nav-button{
      line-height: 38px;

      &.conditional {
        width: auto;
        padding: 0 11px;
        background-color: $medium_gray_508;
      }
    }

    .fake-ribbon.regular {
      width: auto;
      padding: 0 20px;
    }
  }

  footer {
    font-size: 13px;
    font-style: italic;
    margin: 9px 0 33px 0;
    line-height: 1.3;
    padding: 0;

    button {
      background-color: white;
      font-style: italic;
      color: $dark_blue;
      margin-left: 3px;
    }
  }

  .overlay .modal {
    max-height: calc(100vh - 40px);
    padding: 0 20px;

    .sources-modal-content ul {
      padding: 0;

      li {
        max-width: calc(100vw - 80px);
        margin-bottom: 15px;
        list-style-type: none;
        
        &:last-of-type { margin-bottom: 20px;}
        
        p {
          font-size: 13px;
          white-space: normal;
          word-wrap: break-word;
          margin-bottom: 0;
        }
      }
    }

    .no-method-modal-content {
        padding-top: 20px;

      .nav-buttons-container {
        padding-bottom: 20px;

        .nav-button {
          width: auto;
          padding: 0 20px;
        }
      }
    }
  }
}



@media only screen and (min-width: 769px){
  .recommendations .recs-container .rec-section-container .rec-section-icons-container {
    .icon-container {
      margin-right: $max-icon-margin;
      margin-left: $max-icon-margin;
      &:last-of-type { margin-right: 0}
      &:nth-of-type(1) { margin-left: 0}
    }
  }
}

@media only screen and (max-width: 719px){
  .recommendations{
    &.es .recs-toggle { width: 100%; }

    .mobile { display: block }
    .regular { display: none }


    // .heading-container {
    //   @include mobile-heading-style;
    // }

    .recs-toggle { width: 100%;}

    .markdown-block p { @include mobile-paragraph-style; }

    .recs-container .rec-section-container .rec-section-icons-container {
      -webkit-justify-content: flex-start;
      justify-content: flex-start;

      #none-text { font-size: 16px; }

      .icon-container {
        margin-bottom: 20px;
        margin-left: calc((90vw - 4px - #{$small-icon-total-width * 3}) / 6);
        margin-right: calc((90vw - 4px - #{$small-icon-total-width * 3}) / 6);
        &:last-of-type {  margin-right: calc((90vw - 4px - #{$small-icon-total-width * 3}) / 6); }
        &:nth-of-type(1) { margin-left: calc((90vw - 4px - #{$small-icon-total-width * 3}) / 6); }

        .rec-icon-container {
          width: $small-icon-total-width;
          &.active .icon:after {
            height: $small-icon-graphic-width - 2px;
            width: $small-icon-graphic-width - 2px;
            left: 2px;
          }

          .icon {
            width: $small-icon-graphic-width;
            height: $small-icon-graphic-width;
          }
        }

        .info-box { width: calc(45vw + #{$icon-total-width})}
      }
    }

    .overlay {
      top: auto;
      bottom: 0;

      .modal {
        width: auto;
        margin: 20px;
        position: fixed;
        left: 0;

        .modal-content {
          max-height: calc(100vh - 80px);
          overflow-y: auto;

          .heading-container h1{
            font-size: 20px;
            line-height: 24px;
          }

          .markdown-block p { font-size: 13px;}
        }
      }
    }

    .nav-buttons-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      height: 130px;
      -webkit-justify-content: space-between;
      justify-content: space-between;

      .nav-button {
        width: 100%;
        font-size: 20px;
      }
    }

    footer { margin-top: 0 }

    &.es .nav-buttons-container {

      .nav-button.conditional, .nav-button.fake-ribbon {
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 600px){
  .recommendations .recs-toggle { white-space: pre-wrap; }
}

@media only screen and (max-width: 500px){
  .recommendations .recs-container .rec-section-container .rec-section-icons-container .icon-container{
    .info-box {
      width: 88vw;
      max-width: none;
    }

    .rec-icon-container p {
      font-size: 14px;

      span {
        .span-arrow.info-box-up-arrow {
          top: 13px;
        }
        .span-arrow.info-box-down-arrow {
          bottom: 16px;
        }

        .info-box.info-box-down-arrow { bottom: 26px }

        img {
          min-height: 16px;
          min-width: 16px;
        }
      }
    }
  }
}


@media only screen and (max-width: 380px){
  .recommendations .recs-container .rec-section-container .rec-section-icons-container {
    .icon-container{
      margin-left: calc((90vw - 4px - #{$extra-small-icon-total-width * 3}) / 6);
      margin-right: calc((90vw - 4px - #{$extra-small-icon-total-width * 3}) / 6);
      &:last-of-type {
        margin-right: calc((90vw - 4px - #{$extra-small-icon-total-width * 3}) / 6);
      }
      &:nth-of-type(1) { margin-left: calc((90vw - 4px - #{$extra-small-icon-total-width * 3}) / 6); }

      .rec-icon-container{
        width: $extra-small-icon-graphic-width;
        &.active .icon:after {
          height: $extra-small-icon-graphic-width - 2px;
          width: $extra-small-icon-graphic-width - 2px;
        }

        .icon {
          width: $extra-small-icon-graphic-width;
          height: $extra-small-icon-graphic-width;
        }
      }
    }
  }
}
