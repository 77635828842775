@import '../../../scss/colors';
@import '../../../scss/mixins';

.background-image.how_to_men {
  .icon-column-container {
    background-repeat: no-repeat;
    background-image: url('../../../shared_images/silhouettes/male-silhouette-large.png');
    background-position: center bottom -100px;

    .icon-and-map-container {
      margin:100px 0;
    }

    .vasectomy {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/sterilization-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/vasectomy-map.png');
        top: calc(50% - 3px);
        background-position: right;
        width: 230px;
        height: 40px;
      }
    }

    .maleCondom {
      .icon-button {
        background-image: url('../../../shared_images/illustrated_method_icons/male-condom-grey-OFF.png');
        &.color { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-green-ON.png');}
      }
      .map {
        background-image: url('../../../shared_images/illustrated_method_icons/male-condom-map.png');
        top: calc(50% - 26px);
        width: 230px;
        height: 48px
      }
    }

    .icon-and-map-container .info-box {
      &.vasectomy {
        bottom: 25px;
        right: 163px;
      }

      &.maleCondom {
        top: 65px;
        left: 179px;
      }
    }
  }
}

@media only screen and (max-width: 579px){
  .background-image.how_to_men {
    .icon-column-container {
      background-position: center center;

      .icon-and-map-container {
        margin: 60px 0;

        &.maleCondom {
          .map {
            height: 30px;
            width: 130px;
            top: calc(50% - 16px);
          }

          .info-box {
            top: 38px;
            left: 63px;

            .info-box-up-arrow { left: 80px;}
          }
        }

        &.vasectomy {
          .map {
            height: 30px;
            width: 134px;
          }

          .info-box {
            right: 63px; 
            .info-box-down-arrow { left: 80px;}
          }
        }
      }
    }
  }
}
